export const GlobalData = [
    {
        title: 'SAPPHIRE',
        content: 'This plan includes coverage for mental health, major surgery, annual health checks and drug deliveries. Advanced laboratory procedures are also partially covered.',
        icon:'flaticon-insurance'
    },
    {
        title: 'EMERALD',
        content: 'This plan includes everything in Sapphire plus full coverage of advanced clinical investigations. Cancer screening is also covered under the Emerald plan.',
        icon:'flaticon-insurance'
    },
    {
        title: 'RUBY',
        content: 'The most comprehensive of all coverage plans. Includes everything in Emerald, plus concierge healthcare services, and ambulatory services.',
        icon: 'flaticon-insurance'
    },
    {
        title: 'SILVER',
        content: 'This is a base level geriartic plan. It provides broad coverage, including specialist consultations, advanced diagnostic tests, outpatient surgeries and is suited for managing pre-existing conditions.',
        icon: 'flaticon-insurance'
    },
    {
        title: 'PLATINUM',
        content: 'The more comprehensive of geriartric plan. Includes everything in Silver, plus comprehensive coverage for preventive care, wellness programs, mental health services, and emergency medical evacuation.',
        icon: 'flaticon-insurance'
    },
]