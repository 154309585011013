import React, {useEffect} from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
//import '../../../styles/video.css'
import WOW from 'wowjs'
import { SlideData, settings } from './SlideData';
import ReactTyped from "react-typed";
import ImgNextGen from '../../partials/imgNextGen';

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


const DiasporaHero = () => {

  useEffect(()=>{
    new WOW.WOW({
      live:false
    }).init()
  }, [])

  

  return (
    
      <Slider {...settings}>
        {SlideData.map((slide, index) => (
          <div key={index}>
            {slide.type === 'video' ? (
              <>
              <video autoPlay muted loop playsInline preload="auto" className="video-element">
                <source src={slide.content} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="color-overlay" style={{ backgroundColor: slide.colorOverlay}}></div>
              <div className="text-overlay">
                <div className="">
                  {/* desktop */}
                  <div className="hero-content d-none d-lg-block">
                    <h1 style={{fontSize:40, fontFamily:'inherit'}} className="wow fadeInDown text-uppercase" data-wow-delay="1s">
                       Your Partner For...
                       {" "}
                    </h1>
                    <span className='mb-5' style={{fontSize:75, fontWeight:'bolder'}}>
                        <ReactTyped
                          strings={["Individual Health Insurance", "Family Health Insurance", "Corporate Health Insurance"]}
                          typeSpeed={100}
                          loop
                          backSpeed={20}
                          cursorChar=">"
                          showCursor={true}
                        />
                      </span>
                    
                    <div
                      className="hero-button wow fadeInDown"
                      data-wow-delay=".9s"
                    >
                      <Link  to="/insurance-calculator">
                        <span className="main-btn btn-outline">Explore More</span>
                      </Link>
                    </div>
                  </div>

                  {/* mobile */}
                  <div className="hero-content d-lg-none">
                    <h1 style={{fontSize:20, marginBottom:10}} className="wow fadeInDown text-uppercase" data-wow-delay="1s">
                       Your Partner For... 
                       {" "}
                    </h1>
                    <span className='mb-5' style={{fontSize:40, fontWeight:'bolder'}}>
                        <ReactTyped
                          strings={["Individual Health Insurance", "Family Health Insurance", "Corporate Health Insurance"]}
                          typeSpeed={100}
                          loop
                          backSpeed={20}
                          cursorChar=">"
                          showCursor={true}
                        />
                      </span>
                    
                    <div
                      className="hero-button wow fadeInDown"
                      data-wow-delay=".9s"
                    >
                      <Link  to="/insurance-calculator">
                        <span className="main-btn btn-outline">Explore More</span>
                      </Link>
                    </div>
                  </div>
                  {/* end Mobile */}
                </div>
              </div> 
            </>
            ) : (
              <>
              <div className="color-overlay" style={{ backgroundColor: slide.colorOverlay}}></div>
              <div className="container mt-5 diaspora"> {/*style={{ backgroundImage: `url(${slide.content})` }}*/ } 
               
                  <div className="row align-items-center" >
                    <div className="col-xl-7">
                      <div className="shape shape-blur animate-float-y">
                        <span />
                      </div>
                      <div className="hero-content">
                        <h1 style={{fontSize:40, fontFamily:'inherit'}} className="wow fadeInDown" data-wow-delay=".5s">
                          <span>{slide.titleOne}</span> {slide.titleTwo}
                        </h1>
                        <p className="wow fadeInUp" data-wow-delay=".7s">
                          {slide.bodyOne} <br/>
                          {slide.bodyTwo}
                        </p>
                        <div
                          className="hero-button wow fadeInDown mt-4"
                          data-wow-delay=".9s"
                        >
                          <Link  to={slide.urlOne}>
                            <span className="main-btn btn-outline">{slide.buttonOne}</span>
                          </Link>

                          { slide.id=== 2? ( 
                          <Link  data-bs-toggle="modal" data-bs-target="#videoModal"> 
                            <span className="main-btn btn-red">Watch Presentation</span>
                        </Link>):""}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5">
                      <div className="hero-img-box wow fadeInRight" data-wow-delay="1s">
                        <ImgNextGen 
                            srcWebp={`${slide.img2}`} 
                            alt={`${slide.alt}`}
                            fallback={`${slide.img1}`}
                            className="image-element"/>


                        {/* <img src={slide.content} alt={slide.alt} className="image-element" /> */}
                        <div className="circle-border">
                          <span />
                          <span />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              </>
            )}
          </div>
        ))}
      </Slider>
  );
};

export default DiasporaHero