export const plans = [
    {
        id:'plan1',
        plan:'CLASSIC',
        price:'300,000',
        inPatient: '650,000',
        color:'#ECF3E2',
        benefits: [
        {plan: 'Outpatient Care', status: 'fa fa-check-circle text-success'},
        {plan: 'Admission: General ward 7 days', status:'fa fa-check-circle text-success'},
        {plan: 'Specialist Consultation', status : 'fa fa-check-circle text-success'},
        {plan: 'Intermediate Lab Tests', status : 'fa fa-check-circle text-success'},
        {plan: 'Accident & Emergency', status : 'fa fa-check-circle text-success'},
        {plan: 'Neonatal & Paediatric Care', status : 'fa fa-check-circle text-success'},
        // {plan: 'Management of Diabetes', status : 'fa fa-times-circle text-danger'},
        // {plan: 'Dental Care', status: 'fa fa-times-circle text-danger'},
        {plan: 'Physiotherapy', status : 'fa fa-check-circle text-success'},
        // {plan: 'Mental Health', status : 'fa fa-times-circle text-danger'},
        // {plan: 'Major Surgeries', status : 'fa fa-times-circle text-danger'},
        {plan: 'Minor Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Obstetrics & Gynecology', status : 'fa fa-check-circle text-success'},
        {plan: 'Antenatal', status : 'fa fa-check-circle text-success'},
        // {plan: 'Annual Health check-circles', status: 'fa fa-times-circle text-danger'},
        // {plan: 'Cancer Screening*', status : 'fa fa-times-circle text-danger'},
        // {plan: 'Drug Delivery & Pickup', status : 'fa fa-times-circle text-danger'},
        // {plan: 'Personal Health Equipment', status : 'fa fa-times-circle text-danger'}
        ]
    },
    {
        id:'plan2',
        plan:'GOLD',
        price:'510,000',
        inPatient: '1,190,000',
        color:'#BCD59A',
        benefits: [
        {plan: 'Outpatient Care', status: 'fa fa-check-circle text-success'},
        {plan: 'Admission: Semi-private ward 10 days', status:'fa fa-check-circle text-success'},
        {plan: 'Specialist Consultation', status : 'fa fa-check-circle text-success'},
        {plan: 'Intermediate Lab Tests', status : 'fa fa-check-circle text-success'},
        {plan: 'Accident & Emergency', status : 'fa fa-check-circle text-success'},
        {plan: 'Neonatal & Paediatric Care', status : 'fa fa-check-circle text-success'},
        {plan: 'Dental Care', status: 'fa fa-check-circle text-success'},
        {plan: 'Physiotherapy', status : 'fa fa-check-circle text-success'},
        {plan: 'Mental Health', status : 'fa fa-check-circle text-success'},
        {plan: 'Major Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Minor Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Obstetrics & Gynecology', status : 'fa fa-check-circle text-success'},
        {plan: 'Antenatal', status : 'fa fa-check-circle text-success'},
        {plan: 'Annual Health check-circles', status: 'fa fa-check-circle text-success'},
        // {plan: 'Cancer Screening*', status : 'fa fa-times-circle text-danger'},
        {plan: 'Drug Delivery & Pickup', status : 'fa fa-check-circle text-success'},
        // {plan: 'Personal Health Equipment', status : 'fa fa-times-circle text-danger'}
        ]
    },
    {
        id:'plan3',
        plan:'DIAMOND',
        price:'1,890,000',
        inPatient: '810,000',
        color:'#6D903C',
        benefits: [
        {plan: 'Outpatient Care', status: 'fa fa-check-circle text-success'},
        {plan: 'Admission: Semi-private ward 15 days', status:'fa fa-check-circle text-success'},
        {plan: 'Specialist Consultation', status : 'fa fa-check-circle text-success'},
        {plan: 'Advanced Lab Tests', status : 'fa fa-check-circle text-success'},
        {plan: 'Accident & Emergency', status : 'fa fa-check-circle text-success'},
        {plan: 'Neonatal & Paediatric Care', status : 'fa fa-check-circle text-success'},
        {plan: 'Management of common chronic illnesses', status : 'fa fa-check-circle text-success'},
        {plan: 'Dental Care', status: 'fa fa-check-circle text-success'},
        {plan: 'Physiotherapy', status : 'fa fa-check-circle text-success'},
        {plan: 'Mental Health', status : 'fa fa-check-circle text-success'},
        {plan: 'Major Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Minor Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Obstetrics & Gynecology', status : 'fa fa-check-circle text-success'},
        {plan: 'Antenatal', status : 'fa fa-check-circle text-success'},
        {plan: 'Annual Health check-circles', status: 'fa fa-check-circle text-success'},
        {plan: 'Cancer Screening*', status : 'fa fa-check-circle text-success'},
        {plan: 'Drug Delivery & Pickup', status : 'fa fa-check-circle text-success'},
        // {plan: 'Personal Health Equipment', status : 'fa fa-times-circle text-danger'}
        ]
    },
    {
        id:'plan4',
        plan:'DIAMOND+',
        price: '2,540,000',
        inPatient: '1,260,000',
        color:'#37481E',
        benefits: [
        {plan: 'Outpatient Care', status: 'fa fa-check-circle text-success'},
        {plan: 'Admission: Private ward 20 days', status:'fa fa-check-circle text-success'},
        {plan: 'Specialist Consultation', status : 'fa fa-check-circle text-success'},
        {plan: 'Advanced Lab Tests', status : 'fa fa-check-circle text-success'},
        {plan: 'Accident & Emergency', status : 'fa fa-check-circle text-success'},
        {plan: 'Neonatal & Paediatric Care', status : 'fa fa-check-circle text-successfa fa-check-circle text-success'},
        {plan: 'Management of common chronic illnesses', status : 'fa fa-check-circle text-success'},
        {plan: 'Dental Care', status: 'fa fa-check-circle text-success'},
        {plan: 'Physiotherapy', status : 'fa fa-check-circle text-success'},
        {plan: 'Mental Health', status : 'fa fa-check-circle text-success'},
        {plan: 'Major Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Minor Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Obstetrics & Gynecology', status : 'fa fa-check-circle text-success'},
        {plan: 'Antenatal', status : 'fa fa-check-circle text-success'},
        {plan: 'Annual Health check-circles', status: 'fa fa-check-circle text-success'},
        {plan: 'Cancer Screening*', status : 'fa fa-check-circle text-success'},
        {plan: 'Drug Delivery & Pickup', status : 'fa fa-check-circle text-success'},
     //   {plan: 'Personal Health Equipment', status : 'fa fa-check-circle text-success'}
        ]
    }
]