export const plans2 = [
    {
        id:'plan2',
        plan:'SAPPHIRE',
        price:'1,190,000',
        inPatient: '510,000',
        color:'#BCD59A',
        benefits: [

        {plan: 'Admission: Semi-private ward 15 days', status:'fa fa-check-circle text-success'},
        {plan: 'Specialist Consultation', status : 'fa fa-check-circle text-success'},
        {plan: 'Advanced Lab Tests', status : 'fa fa-check-circle text-success'},
        {plan: 'Accident & Emergency', status : 'fa fa-check-circle text-success'},
        {plan: 'Neonatal & Paediatric Care', status : 'fa fa-check-circle text-success'},
        {plan: 'Dental Care', status: 'fa fa-check-circle text-success'},
        {plan: 'Physiotherapy', status : 'fa fa-check-circle text-success'},
        {plan: 'Mental Health', status : 'fa fa-check-circle text-success'},
        {plan: 'Major Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Minor Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Obstetrics & Gynecology', status : 'fa fa-check-circle text-success'},
        {plan: 'Antenatal', status : 'fa fa-check-circle text-success'},
        {plan: 'Annual Health check-circles', status: 'fa fa-check-circle text-success'},
        {plan: 'Pre-booking', status: 'fa fa-check-circle text-success'},
        {plan: 'Telemedicine', status: 'fa fa-check-circle text-success'},

        ]
    },
    {
        id:'plan3',
        plan:'EMERALD',
        price:'1,890,000',
        inPatient: '810,000',
        color:'#6D903C',
        benefits: [

        {plan: 'Admission: Semi-private ward 30 days', status:'fa fa-check-circle text-success'},
        {plan: 'Specialist Consultation', status : 'fa fa-check-circle text-success'},
        {plan: 'Advanced Lab Tests', status : 'fa fa-check-circle text-success'},
        {plan: 'Accident & Emergency', status : 'fa fa-check-circle text-success'},
        {plan: 'Neonatal & Paediatric Care', status : 'fa fa-check-circle text-success'},
        {plan: 'Management of Chronic Illnesses', status : 'fa fa-check-circle text-success'},
        {plan: 'Dental Care', status: 'fa fa-check-circle text-success'},
        {plan: 'Physiotherapy', status : 'fa fa-check-circle text-success'},
        {plan: 'Mental Health', status : 'fa fa-check-circle text-success'},
        {plan: 'Major Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Minor Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Obstetrics & Gynecology', status : 'fa fa-check-circle text-success'},
        {plan: 'Antenatal', status : 'fa fa-check-circle text-success'},
        {plan: 'Annual Health check-circles', status: 'fa fa-check-circle text-success'},
        {plan: 'Cancer Screening*', status : 'fa fa-check-circle text-success'},
        {plan: 'Pre-booking', status: 'fa fa-check-circle text-success'},
        {plan: 'Telemedicine', status: 'fa fa-check-circle text-success'},
        // {plan: 'Drug Delivery & Pickup', status : 'fa fa-check-circle text-success'},
        // {plan: 'Personal Health Equipment', status : 'fa fa-times-circle text-danger'},
        // {plan: 'Delivery Abroad', status : 'fa fa-check-circle text-success'}
        ]
    },
    {
        id:'plan4',
        plan:'RUBY',
        price: '2,940,000',
        inPatient: '1,260,000',
        color:'#37481E',
        benefits: [
 
        {plan: 'Admission: Private ward 30 days', status:'fa fa-check-circle text-success'},
        {plan: 'Specialist Consultation', status : 'fa fa-check-circle text-success'},
        {plan: 'Advanced Lab Tests', status : 'fa fa-check-circle text-success'},
        {plan: 'Accident & Emergency', status : 'fa fa-check-circle text-success'},
        {plan: 'Neonatal & Paediatric Care', status : 'fa fa-check-circle text-successfa fa-check-circle text-success'},
        {plan: 'Management of Chronic Illnesses', status : 'fa fa-check-circle text-success'},
        {plan: 'Dental Care', status: 'fa fa-check-circle text-success'},
        {plan: 'Physiotherapy', status : 'fa fa-check-circle text-success'},
        {plan: 'Mental Health', status : 'fa fa-check-circle text-success'},
        {plan: 'Major Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Minor Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Obstetrics & Gynecology', status : 'fa fa-check-circle text-success'},
        {plan: 'Antenatal', status : 'fa fa-check-circle text-success'},
        {plan: 'Annual Health check-circles', status: 'fa fa-check-circle text-success'},
        {plan: 'Cancer Screening*', status : 'fa fa-check-circle text-success'},
        {plan: 'Pre-booking', status: 'fa fa-check-circle text-success'},
        {plan: 'Telemedicine', status: 'fa fa-check-circle text-success'},

        ]
    },
    {
        id:'plan5',
        plan:'SILVER',
        price: '360,000',
        inPatient: '840,000',
        color:'#C0C0C0',
        benefits: [

        {plan: 'Admission: Semi-private ward 15 days', status:'fa fa-check-circle text-success'},
        {plan: 'Specialist Consultation', status : 'fa fa-check-circle text-success'},
        {plan: 'Advanced Lab Tests', status : 'fa fa-check-circle text-success'},
        {plan: 'Accident & Emergency', status : 'fa fa-check-circle text-success'},
        {plan: 'Management of Chronic Illnesses', status : 'fa fa-check-circle text-success'},
        {plan: 'Dental Care', status: 'fa fa-check-circle text-success'},
        {plan: 'Physiotherapy', status : 'fa fa-check-circle text-success'},
        {plan: 'Mental Health', status : 'fa fa-check-circle text-success'},
        {plan: 'Major Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Minor Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Annual Health check-circles', status: 'fa fa-check-circle text-success'},
        {plan: 'Pre-booking', status: 'fa fa-check-circle text-success'},
        {plan: 'Telemedicine', status: 'fa fa-check-circle text-success'},
        {plan: 'Home Nursing (add-on)', status: 'fa fa-check-circle text-success'},

        ]
    },
    {
        id:'plan6',
        plan:'PLATINUM',
        price: '660,000',
        inPatient: '1,540,000',
        color:'#E5E4E2',
        benefits: [
 
        {plan: 'Admission: Private ward 20 days', status:'fa fa-check-circle text-success'},
        {plan: 'Specialist Consultation', status : 'fa fa-check-circle text-success'},
        {plan: 'Advanced Lab Tests', status : 'fa fa-check-circle text-success'},
        {plan: 'Accident & Emergency', status : 'fa fa-check-circle text-success'},
        {plan: 'Management of Chronic Illnesses', status : 'fa fa-check-circle text-success'},
        {plan: 'Dental Care', status: 'fa fa-check-circle text-success'},
        {plan: 'Physiotherapy', status : 'fa fa-check-circle text-success'},
        {plan: 'Mental Health', status : 'fa fa-check-circle text-success'},
        {plan: 'Major Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Minor Surgeries', status : 'fa fa-check-circle text-success'},
        {plan: 'Annual Health check-circles', status: 'fa fa-check-circle text-success'},
        {plan: 'Cancer Screening*', status : 'fa fa-check-circle text-success'},
        {plan: 'Pre-booking', status: 'fa fa-check-circle text-success'},
        {plan: 'Telemedicine', status: 'fa fa-check-circle text-success'},
        {plan: 'Home Nursing (add-on)', status: 'fa fa-check-circle text-success'},

        ]
    }
]