import anjela from '../../../../images/board/anjela.jpg'
import otumba from '../../../../images/board/otumba.jpg'
import bob from '../../../../images/board/bob.jpg'
import abdulaziz from '../../../../images/board/abdulaziz.jpg'
import susan from '../../../../images/board/susan.jpg'
import ephraim from '../../../../images/board/ephraim_4.jpg'
import anjela2 from '../../../../images/board/anjela.webp'
import otumba2 from '../../../../images/board/otumba.webp'
import bob2 from '../../../../images/board/bob.webp'
import abdulaziz2 from '../../../../images/board/abdulaziz.webp'
import susan2 from '../../../../images/board/susan.webp'
import ephraim2 from '../../../../images/board/ephraim_4.webp'

export const teamData = [
    {
        id:1,
        name:'Mrs Anjela Ajala',
        position:'Chairman',
        image:anjela,
        image2:anjela2
    },
    {
        id:2,
        name:'Otunba Lekan Ewenla M.CIoD',
        position:'Managing Director/CEO',
        image:otumba,
        image2:otumba2
    },
    {
        id:3,
        name:'Dr. M.S.D. Bob Manuel',
        position:'Non-Executive Director',
        image:bob,
        image2:bob2
    },
    {
        id:4,
        name:'Alhaji Abdulaziz Abdullahi',
        position:'Independent Non-Executive Director',
        image:abdulaziz,
        image2:abdulaziz2
    },
    {
        id:5,
        name:'Mrs Susan Giwa-Osagie',
        position:'Independent Non-Executive Director',
        image:susan,
        image2:susan2
    },
    {
        id:6,
        name:'S. Ephraim-Oluwanuga, C.Arb',
        position:'Company Secretary',
        image:ephraim,
        image2:ephraim2
    }
    
]