import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  imgItems: [],
  isLoading: true,
  
}





const imgSrc = "media?_fields=id,link&per_page=50"


const url2 = process.env.REACT_APP_WORDPRESS_POST_URL+imgSrc;






export const getFImages = createAsyncThunk('blogImg/getFImages', async () =>{
  try{
    const imgRes = await axios(url2);
    return imgRes.data;
  }catch(error){

  }
})

const imgSlice = createSlice({
  name: "blogImg",
  initialState,
  reducers: {

    // getImages: (state, action) => {
	
    //   const index = state.findIndex((postItems) => postItems.id === action.payload.id);
	// 		state[index].imgUrl = action.payload.link;
	// 		return state;
	// 	},
      
    },
    extraReducers:{      

      [getFImages.pending]:(state)=>{
        state.isLoading = true
      },
      [getFImages.fulfilled]:(state, action)=>{
        state.isLoading = false
        state.imgItems = action.payload
      },
      [getFImages.rejected]:(state, action)=>{
        state.isLoading = false
      }
      
    }
  }
)

export default imgSlice.reducer