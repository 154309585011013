import React, { useEffect, useState, useMemo } from 'react'
import Pagination from 'react-bootstrap/Pagination'

const PaginationComponent = ({total=0, itemsPerPage=100, currentPage=1, onPageChange}) => {

  const [totalPages, setTotalPages] = useState(0);
  
  useEffect(()=>{
    if(total > 0 && itemsPerPage > 0)
    setTotalPages(Math.ceil(total/itemsPerPage));
  },[total, itemsPerPage])

  const paginationItems = useMemo(()=>{
    const pages = [];

    for(let i = 1; i<= totalPages; i++){
      pages.push(<Pagination.Item key={i} active = {i === currentPage} activeLabel={''} onClick={()=>onPageChange(i)}>
        {i}
      </Pagination.Item>)
    }
    return pages


  },[totalPages, currentPage, onPageChange]);

  if(totalPages === 0 ) return null

  return (
    <Pagination>
        <Pagination.Next onClick={()=>onPageChange(currentPage + 1)} disabled={currentPage === totalPages}/>
          {paginationItems}
        <Pagination.Prev onClick={()=>onPageChange(currentPage - 1)} disabled = {currentPage === 1}/>
    </Pagination>
  )
}

export default PaginationComponent