export const plans = [
    {
        id:1,
        plan:'Classic',
        amount:'86250'
    },
    {
        id:2,
        plan:'Gold',
        amount:'121036'
    },
    {
        id:3,
        plan:'Diamond',
        amount:'209548'
    },
    {
        id:4,
        plan:'Diamond+',
        amount:'319190'
    }
]