import React from 'react'
import TISHIP from './TISHIP'
import GIFSHIP from './GIFSHIP'
import StateHealth from './StateHealth'

const SocialPrograms = () => {
  return (
    <>
        <GIFSHIP/>
        <TISHIP/>
        <StateHealth/>
    </>
  )
}

export default SocialPrograms