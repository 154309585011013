export const plans2 = [
    {
        id:2,
        plan:'Sapphire',
        amount:'171.99'
    },
    {
        id:3,
        plan:'Emerald',
        amount:'295.99'
    },
    {
        id:4,
        plan:'Ruby',
        amount:'449.99'
    },
    {
        id:5,
        plan:'Silver',
        amount:'537.08'
    },
    {
        id:6,
        plan:'Platinum',
        amount:'953.86'
    }
]