export const SlideData = [
   {
      id:0,
      titleOne: 'Are Your Loved Ones',
      titleTwo: 'Getting the Healthcare they desire?',
      bodyOne: '',
      bodyTwo: "Ultimate Health Management Services has addressed this dilenma with our diaspora health insurance packages. Our Diaspora Health Insurance Plans offer comprehensive and personalized healthcare solutions for you and your family in Nigeria. From preventative care to specialized treatments, we've got you covered. Reach out to us today for peace of mind and quality healthcare services.",
      buttonOne: 'See Our Plans',
      buttonTwo: 'Explore More 2',
      urlOne: "/insurance-plans",
      alt:'Asher_UHMS',
      img1:'assets/images/hero/hero1.png',
      img2:'assets/images/hero/hero1.webp',
      colorOverlay: 'rgba(142,184,80,0.01)',
  },{
      id:1,
      titleOne: 'Thinking About Your',
      titleTwo: "Family's Health in Nigeria?",
      bodyOne: "",
      bodyTwo: "Ultimate Health Management Services has addressed this dilenma with our diaspora health insurance packages. Our Diaspora Health Insurance Plans offer comprehensive and personalized healthcare solutions for you and your family in Nigeria. From preventative care to specialized treatments, we've got you covered. Reach out to us today for peace of mind and quality healthcare services.",
      buttonOne: 'See Our Plans',
      buttonTwo: 'Explore More 2',
      urlOne: "/insurance-plans",
      alt:'Eko_UHMS',
      img1:'assets/images/hero/hero2.png',
      img2:'assets/images/hero/hero2.webp',
      colorOverlay: 'rgba(142,184,80,0.01)',
  },{
      id:2,
      titleOne: "Don't Send Money For",
      titleTwo: 'Another Medical Bill',
      bodyOne: "The ability to take care of the medical needs of loved ones in Nigeria from Diaspora can be a source of pride and joy. Raising and sending money back home for unplanned medical expenditures can distort and disrupt individual's financial plan and derail future plans.",
      bodyTwo: 'Ultimate Health HMO has been in the health insurance ecosystem in Nigeria for over 20 Years driving the health insurance program with innovation, flexibility and integrity.  We are here to partner with you to enroll your dependants back in Nigeria on the health insurance program and even yourself, whenever you find yourself back home. It might interest you to note that the law has been passed in Nigeria to make health insurance mandatory for all Nigerians and legal residents in Nigeria.',
      buttonOne: 'See Our Plans',
      buttonTwo: 'Watch the Presentation',
      urlOne: "/insurance-plans",
      alt:'guinea_savannah_uhms',
      img1:'assets/images/hero/hero3.png',
      img2:'assets/images/hero/hero3.webp',
      colorOverlay: 'rgba(142,184,80,0.01)',
    }

  ];





export const settings = {
    dots:true,
    dotsClass: "slick-dots",
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows:true,
    autoplaySpeed:3500,
    initialSlide: 0,
    
    nextArrow: (
      <div >
        <div className="next-slick-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" stroke="black" height="24" viewBox="0 -960 960 960" width="24"><path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z"/></svg>
        </div>
      </div>
    ),
  
    prevArrow: (
      <div >
        <div className="prev-slick-arrow rotate-180">
          <svg xmlns="http://www.w3.org/2000/svg" stroke="black" height="24" viewBox="0 -960 960 960" width="24"><path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z"/></svg>
        </div>
      </div>
    ),



  };